// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    jwtSecretKey:
        import.meta?.env?.['NG_SECRET_KEY'] != undefined &&
        import.meta?.env?.['NG_SECRET_KEY'] != ''
            ? import.meta?.env?.['NG_SECRET_KEY']
            : '',
    api_url:
        import.meta?.env?.['NG_APP_URL'] != undefined &&
        import.meta?.env?.['NG_APP_URL'] != ''
            ? import.meta?.env?.['NG_APP_URL']
            : '',
    image_url:
        import.meta?.env?.['NG_APP_MEDIA_URL'] != undefined &&
        import.meta?.env?.['NG_APP_MEDIA_URL'] != ''
            ? import.meta?.env?.['NG_APP_MEDIA_URL']
            : '',
    sentry_DSN:
        import.meta?.env?.['NG_APP_SENTRY_DSN'] != undefined &&
        import.meta?.env?.['NG_APP_SENTRY_DSN'] != ''
            ? import.meta?.env?.['NG_APP_SENTRY_DSN']
            : '',
};

/*
 * For easier debugging in development mode, you can import the following fil
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
